import React from "react";

export default props => {
  return (
    <div className="patient-fam patient-fam--padding">
      <ul className="nav nav-pills">
        <li role="presentation">
          {" "}
          <a>Updates</a>
        </li>

        <li role="presentation" className="patient-fam-arrow hidden-xs  ">
          <a href="#" id="sidebarCollapse">
            <i className="fa fa-chevron-right"></i>{" "}
          </a>
        </li>
      </ul>
    </div>
  );
};
