import React, { Component } from 'react';
import { connect } from "react-redux";
import userAccountApi from '../../api/user-account-api';
import defaultColumns from "./default-columns.json";
import { createUUID } from '../react-flow-pedigree/utils';
import CopySurveyModal from './survey-copy-modal';
import SurveySearch from './survey-search';
import DeleteSurveyModal from './survey-delete-modal';
import * as helper_search from "../../helpers/helper-search";

interface  ModalProps{
    searchFilter: any;
    dispatch: (action:any) => void;
  }

interface State{
  surveyList: Array<any>;
  columns: Array<any>;
  searchText: string;
  showCopyModal: boolean;
  showdeleteSurveyModal: boolean;
  surveyToCopy: any;
  surveyToDeleteName: string;
  surveyToDeleteId: any;

}

type Survey = {
  name: string;
  organization: string;
  id: number
  translation_key: string;
}

type CopySurveyPayload = {
  newSurveyName: string;
  surveyToCopy: string;
}

type DeleteSurveyPayload = {
  surveyToDelete: string
}


class SurveyList extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          surveyList: [],
          columns: [],
          searchText: "",
          showCopyModal: false,
          surveyToCopy: null,
          surveyToDeleteName: "",
          showdeleteSurveyModal: false,
          surveyToDeleteId: null

          
        };

      this.componentDidMount = this.componentDidMount.bind(this);
      this.searchSurveys = this.searchSurveys.bind(this);
      this.openCopyModal = this.openCopyModal.bind(this);
      this.onClose = this.onClose.bind(this);
      this.fetchSurveys = this.fetchSurveys.bind(this);

    }
  async componentDidMount(){
    await this.fetchSurveys()
  }

  componentWillUnmount(): void {
    helper_search.updateSearchText(this.props.dispatch, "");
  }

  async fetchSurveys(){
    let payload = {
      search_text: this.props.searchFilter.search_text
    }
    try{
      let surveys = await userAccountApi.get_surveys_with_orgs(payload)
      this.setState({
        surveyList: surveys,
        columns: defaultColumns
      })
    }catch(err){
      console.dir(err)
    }
  }

  openCopyModal(survey: Survey){

    this.setState({
      showCopyModal: true,
      showdeleteSurveyModal: false,
      surveyToCopy: survey.id
    })
  }

  openDeleteModal(survey: Survey){

    this.setState({
      showCopyModal: false,
      showdeleteSurveyModal: true,
      surveyToDeleteName: survey.name,
      surveyToDeleteId: survey.id
    })
  }

  onClose(){
    this.setState({
      showCopyModal: false, 
      showdeleteSurveyModal: false
    })
  }

  async onSave(title: string){
    
    let payload: CopySurveyPayload = {
      newSurveyName: title,
      surveyToCopy: this.state.surveyToCopy
    }
    
    try{
      await userAccountApi.create_new_survey_copy(payload)
    }catch(err){
      console.log(err)
    }finally{
      await this.fetchSurveys()
      this.onClose()
    }
    
  }

  async deleteSurvey(){
    let payload: DeleteSurveyPayload = {
      surveyToDelete: this.state.surveyToDeleteId,
    }
    try{
      await userAccountApi.delete_survey(payload)
    }catch(err){
      console.log(err)
    }finally{
      await this.fetchSurveys()
      this.onClose()
    }
  }

  async searchSurveys(){
    console.log('search')
    await this.fetchSurveys()
  }

render() {
    return(
      <React.Fragment>
        <div id="survey-search">
          <SurveySearch 
              searchSurveys={this.searchSurveys}
          />             
          <table id="surveyTable" className="table table-custom ">             
              <thead>
                <tr>
                  {this.state.columns.map((item , index)=> {
                    return <th key={index}>{item.label}</th>
                  })}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.state.surveyList.map(survey => {
                  return (
                    <React.Fragment key={createUUID()}>
                      <tr>
                        {this.state.columns.map(item => {
                          if (item.value === 'name') {
                            return <td key={createUUID()}>{survey['name']}</td>
                          }
                          else if(item.value === 'organization'){
                            return <td key={createUUID()}>{survey['organizations']}</td>
                          }
                        })}
                        <td className="middle">
                          <button  className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openCopyModal(survey)}><i className="fa fa-eye"></i>&nbsp;Copy</button>
                          <button  className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openDeleteModal(survey)}><i className="fa fa-eye"></i>&nbsp;Delete</button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>


          {this.state.showCopyModal &&(
              <CopySurveyModal 
                modalTitle='Copy Survey'
                onClose={this.onClose}
                onSave={(name)=> this.onSave(name)}
              />
            )}
          {this.state.showdeleteSurveyModal &&(
              <DeleteSurveyModal
                survey={this.state.surveyToDeleteName}
                modalTitle='Delete Survey'
                onClose={this.onClose}
                onDelete={()=>this.deleteSurvey()}
              />
            )}
        </div>
      </React.Fragment>
    
    );
  };
}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyList);
