export default {
	root: "/",
  patient: {
    patient_root:    "/patient",
	flux_root: "/flux",
    patient_information: "/patient-information/:member_id",
		patient_profile: "/patient-profile/:member_id"
  },
  family: {
    family_root:    "/family",
    family_tree:    "/family-tree/:member_id",
    family_history: "/family-history/:member_id"
  },
  pedigree: {
	patient_pedigree_landing: '/patient-pedigree/',
    pedigree_root: "/pedigree/:member_id"
  },
  query: {
    query_root: "/query"
  },
	administrative: {
		staff: "/staff",
		user_profile: "/user-profile",
		fh_dashboard: "/fh-dashboard",
		fh_new_account: "/fh-new-account",
		fh_super_account: "/fh-super-account/:org_id",
		fh_sub_account: "/fh-sub-account/:org_id",
		clinician_super_account: "/clinician-super-account/:org_id",
		clinician_sub_account: "/clinician-sub-account/:org_id"
	},
	password: {
		password_reset_confirmation: "/password-reset/:uid64/:token",
		create_password: "/create-password/:uid64/:token"
	},
	survey: {
		survey_list: "/survey_list"
	},
	read_only: {
		emr_authentication: "/emrintegration",
		emr_patient_search: "/emr_patients"
	},
	saml_login: {
		authentication: "/login"
	},
	oauth_login: {
		authentication: "/oauth"
	},
	authenticator: {
		registration: "/authenticator-registration",
		authenticate: "/authenticator-authenticate"
	}
}
