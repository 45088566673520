import React, { Component } from "react";
import { connect } from 'react-redux';
import TabPending from './tab-pending';
import TabCompleted from './tab-completed';
import TabAll from './tab-all';
import * as helper_error from "../../../helpers/helper-error"
import { acceptOrDenyNotification } from "../../../helpers/helper-notifications"
import helper from "../../../helpers";
import ModalError from "../../modal-error";

class NotificationTabs extends Component {
  state = {
    active_tab: 1,
    loading: false,
    errorMessages: [],
    active_notification_id: undefined,
    show_modal_error: false
  };

  constructor(props) {
    super(props);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  toggleTab(tab) {
    this.setState({ active_tab: tab });
  }

  async handleAccept(notification) {
    await this.accept_or_deny(notification.id, "accept");
  }

  async handleDecline(notification) {
    await this.accept_or_deny(notification.id, "deny");
  }

  async accept_or_deny(notification_id, action) {
    try {
      this.setState({ loading: true, active_notification_id: notification_id });

      let { clinician_id, user_id } = this.props.user;
      await acceptOrDenyNotification(notification_id, clinician_id, user_id, action);
      await this.props.onNotificationAction();

    } catch (error) {
      this.setState({ show_modal_error: true });
      helper_error.log_error(error);
    } finally {
      await helper.delay(1);
      this.setState({ loading: false, active_notification_id: undefined });
    }
  }

  async handleAcceptAll(notifications) {

    try {
      this.setState({ loading: true });

      for(let notification of notifications) {
        this.setState({ loading: true, active_notification_id: notification.id });

        let { clinician_id, user_id } = this.props.user;
        await acceptOrDenyNotification(notification.id, clinician_id, user_id, "accept");
      }

      await this.props.onNotificationAction();
    } catch (error) {
      this.setState({ show_modal_error: true });
      helper_error.log_error(error);
    } finally {
      await helper.delay(1);
      this.setState({ loading: false, active_notification_id: undefined });
    }

  }

  render() {
    let { active_tab } = this.state;
    let updates = this.props.notificationUpdates;
    let pending_notis = updates.filter(n => n.status === "pending");
    let accepted_notis = updates.filter(n => n.response === "accept");
    let rejected_notis = updates.filter(n => n.response === "deny");

    return (
      <div className="patient-fam-content">
        <ul className="nav nav-tabs">
          <li
            className={
              "nav-tabs--notifications " + (active_tab == 1 ? "active" : "")
            }
          >
            <a data-toggle="tab" onClick={() => this.toggleTab(1)}>
              Pending ({pending_notis.length})
            </a>
          </li>
          <li
            className={
              "nav-tabs--notifications " + (active_tab == 2 ? "active" : "")
            }
          >
            <a data-toggle="tab" onClick={() => this.toggleTab(2)}>
              Accepted ({accepted_notis.length})
            </a>
          </li>
          <li
            className={
              "nav-tabs--notifications " + (active_tab == 3 ? "active" : "")
            }
          >
            <a data-toggle="tab" onClick={() => this.toggleTab(3)}>
              Rejected ({rejected_notis.length})
            </a>
          </li>
          <li
            className={
              "nav-tabs--notifications " + (active_tab == 4 ? "active" : "")
            }
          >
            <a data-toggle="tab" onClick={() => this.toggleTab(4)}>
              All ({updates.length})
            </a>
          </li>
        </ul>

        <div className="tab-content" style={{ paddingTop: "25px" }}>
          <TabPending
            active={active_tab == 1}
            notifications={pending_notis}
            activeNotificationId={this.state.active_notification_id}
            onAccept={this.handleAccept}
            onDecline={this.handleDecline}
            onAcceptAll={() => this.handleAcceptAll(pending_notis)}
          />

          <TabCompleted
            active={active_tab == 2}
            notifications={accepted_notis}
          />

          <TabCompleted
            active={active_tab == 3}
            notifications={rejected_notis}
          />

          <TabAll
            active={active_tab == 4}
            notifications={updates}
            activeNotificationId={this.state.active_notification_id}
            onAccept={this.handleAccept}
            onDecline={this.handleDecline}
            onAcceptAll={() => this.handleAcceptAll(pending_notis)}
            pending_length={pending_notis.length}
          />
        </div>

        {this.state.show_modal_error && (
          <ModalError
            onClose={() => this.setState({ show_modal_error: false })}
          />
        )}
      </div>
    );
  }
};

const mapStateToProps = state => ({
  user: state.session.user
});

export default connect(mapStateToProps)(NotificationTabs);
