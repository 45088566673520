const marriage_status_options = [
    { value: 'married', label: 'Married' },
    { value: 'separated', label: 'Separated' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'other', label: 'Other' }
]

const pregnancy_options = [
  { value: 'm', label: 'Male Fetus' },
  { value: 'f', label: 'Female Fetus' },
  { value: 'u', label: 'Unknown' }
]

const pregnancy_options_2 = [
  { value: 'live-birth', label: 'Live Birth' },
  { value: 'pregnancy', label: 'Pregnancy' },
  { value: 'stillbirth', label: 'Stillbirth' },
  { value: 'miscarriage', label: 'Miscarriage' },
  { value: 'termination', label: 'Termination' }
]

const twins_set_options = [
  { value: '1', label: 'Set 1' },
  { value: '2', label: 'Set 2' },
  { value: '3', label: 'Set 3' },
  { value: '4', label: 'Set 4' },
  { value: '5', label: 'Set 5' },
]

const twins_type_options = [
  { value: 'fraternal', label: 'Fraternal' },
  { value: 'identical', label: 'Identical' },
  { value: 'not sure', label: 'Not Sure' },
]

const adopted_status_options = [
  { value: 'adopted in', label: 'Adopted In' },
  { value: 'adopted out', label: 'Adopted Out' }
]

const half_siblings_shared_parent_options = [
  { value: 'father', label: 'Father' },
  { value: 'mother', label: 'Mother' }
]

const half_siblings_other_parent_options = [
  { value: '1', label: 'Partner #1' },
  { value: '2', label: 'Partner #2' },
  { value: '3', label: 'Partner #3' },
  { value: '4', label: 'Partner #4' },
  { value: '5', label: 'Partner #5' },
]

const ethnicity_options = [
  { value: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native' },
  { value: 'Asian:Chinese', label: 'Asian:Chinese' },
  { value: 'Asian:Filipino', label: 'Asian:Filipino' },
  { value: 'Asian:Japanese', label: 'Asian:Japanese' },
  { value: 'Asian:Other Asian', label: 'Asian:Other Asian' },
  { value: 'Black or African American', label: 'Black or African American' },
  { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
  { value: 'Native Hawaiian', label: 'Native Hawaiian' },
  { value: 'Other Pacific Islander', label: 'Other Pacific Islander' },
  { value: 'Other / Unknown', label: 'Other / Unknown' },
  { value: 'White', label: 'White' }
]

const ancestry = [
	{ value: "afghan", label: "Afghan" },
	{ value: "african", label: "African" },
	{ value: "african american", label: "African American" },
	{ value: "albanian", label: "Albanian" },
	{ value: "algerian", label: "Algerian" },
	{ value: "american", label: "American" },
	{ value: "american indian/native american", label: "American Indian/Native American" },
	{ value: "andorran", label: "Andorran" },
	{ value: "angolan", label: "Angolan" },
	{ value: "anguillan", label: "Anguillan" },
	{ value: "antiguan", label: "Antiguan" },
	{ value: "arab", label: "Arab" },
	{ value: "argentinean", label: "Argentinean" },
	{ value: "armenian", label: "Armenian" },
	{ value: "ashkenazi jewish", label: "Ashkenazi Jewish" },
	{ value: "asian", label: "Asian" },
	{ value: "asian indian", label: "Asian Indian" },
	{ value: "assyrian/chaldean/syriac", label: "Assyrian/Chaldean/Syriac" },
	{ value: "australian", label: "Australian" },
	{ value: "austrian", label: "Austrian" },
	{ value: "azerbaijani", label: "Azerbaijani" },
	{ value: "bahamian", label: "Bahamian" },
	{ value: "bahraini", label: "Bahraini" },
	{ value: "bangladeshi", label: "Bangladeshi" },
	{ value: "barbadian", label: "Barbadian" },
	{ value: "barbudan", label: "Barbudan" },
	{ value: "basque", label: "Basque" },
	{ value: "belarusian", label: "Belarusian" },
	{ value: "belgian", label: "Belgian" },
	{ value: "belizean", label: "Belizean" },
	{ value: "beninese", label: "Beninese" },
	{ value: "bermudian", label: "Bermudian" },
	{ value: "bhutanese", label: "Bhutanese" },
	{ value: "bissau-guinean", label: "Bissau-Guinean" },
	{ value: "bolivian", label: "Bolivian" },
	{ value: "bosnian", label: "Bosnian" },
	{ value: "botswanan", label: "Botswanan" },
	{ value: "brazilian", label: "Brazilian" },
	{ value: "british", label: "British" },
	{ value: "british virgin islander", label: "British Virgin Islander" },
	{ value: "british west indian", label: "British West Indian" },
	{ value: "bruneian", label: "Bruneian" },
	{ value: "bulgarian", label: "Bulgarian" },
	{ value: "burkinabe", label: "Burkinabe" },
	{ value: "burmese", label: "Burmese" },
	{ value: "burundian", label: "Burundian" },
	{ value: "cajun", label: "Cajun" },
	{ value: "cambodian", label: "Cambodian" },
	{ value: "cameroonian", label: "Cameroonian" },
	{ value: "canadian", label: "Canadian" },
	{ value: "cape verdean", label: "Cape Verdean" },
	{ value: "cayman islander", label: "Cayman Islander" },
	{ value: "celtic", label: "Celtic" },
	{ value: "central african", label: "Central African" },
	{ value: "chadian", label: "Chadian" },
	{ value: "chilean", label: "Chilean" },
	{ value: "chinese", label: "Chinese" },
	{ value: "colombian", label: "Colombian" },
	{ value: "comoran", label: "Comoran" },
	{ value: "congolese", label: "Congolese" },
	{ value: "cook islander", label: "Cook Islander" },
	{ value: "costa rican", label: "Costa Rican" },
	{ value: "croatian", label: "Croatian" },
	{ value: "cuban", label: "Cuban" },
	{ value: "cymraes", label: "Cymraes" },
	{ value: "cymro", label: "Cymro" },
	{ value: "cypriot", label: "Cypriot" },
	{ value: "czech", label: "Czech" },
	{ value: "czechoslovakian", label: "Czechoslovakian" },
	{ value: "danish", label: "Danish" },
	{ value: "djiboutian", label: "Djiboutian" },
	{ value: "dominican", label: "Dominican" },
	{ value: "dutch", label: "Dutch" },
	{ value: "dutch west indian", label: "Dutch West Indian" },
	{ value: "east timorese", label: "East Timorese" },
	{ value: "eastern european", label: "Eastern European" },
	{ value: "ecuadorian", label: "Ecuadorian" },
	{ value: "egyptian", label: "Egyptian" },
	{ value: "emirati", label: "Emirati" },
	{ value: "english", label: "English" },
	{ value: "equatorial guinean", label: "Equatorial Guinean" },
	{ value: "eritrean", label: "Eritrean" },
	{ value: "estonian", label: "Estonian" },
	{ value: "ethiopian", label: "Ethiopian" },
	{ value: "european", label: "European" },
	{ value: "faroese", label: "Faroese" },
	{ value: "fijian", label: "Fijian" },
	{ value: "filipino", label: "Filipino" },
	{ value: "finnish", label: "Finnish" },
	{ value: "french", label: "French" },
	{ value: "french canadian", label: "French Canadian" },
	{ value: "gabonese", label: "Gabonese" },
	{ value: "gambian", label: "Gambian" },
	{ value: "georgian", label: "Georgian" },
	{ value: "german", label: "German" },
	{ value: "ghanaian", label: "Ghanaian" },
	{ value: "gibraltarian", label: "Gibraltarian" },
	{ value: "greek", label: "Greek" },
	{ value: "greenlandic", label: "Greenlandic" },
	{ value: "grenadian", label: "Grenadian" },
	{ value: "guamanian", label: "Guamanian" },
	{ value: "guatemalan", label: "Guatemalan" },
	{ value: "guinean", label: "Guinean" },
	{ value: "guyanese", label: "Guyanese" },
	{ value: "haitian", label: "Haitian" },
	{ value: "hawaiian", label: "Hawaiian" },
	{ value: "herzegovinian", label: "Herzegovinian" },
	{ value: "hmong", label: "Hmong" },
	{ value: "honduran", label: "Honduran" },
	{ value: "hong Konger", label: "Hong Konger" },
	{ value: "hungarian", label: "Hungarian" },
	{ value: "icelandic", label: "Icelandic" },
	{ value: "i-kiribati", label: "I-Kiribati" },
	{ value: "indian", label: "Indian" },
	{ value: "indonesian", label: "Indonesian" },
	{ value: "iranian", label: "Iranian" },
	{ value: "iraqi", label: "Iraqi" },
	{ value: "irish", label: "Irish" },
	{ value: "israeli", label: "Israeli" },
	{ value: "italian", label: "Italian" },
	{ value: "ivorian", label: "Ivorian" },
	{ value: "jamaican", label: "Jamaican" },
	{ value: "japanese", label: "Japanese" },
	{ value: "jordanian", label: "Jordanian" },
	{ value: "kazakhstani", label: "Kazakhstani" },
	{ value: "kenyan", label: "Kenyan" },
	{ value: "kittitian or nevisian", label: "Kittitian or Nevisian" },
	{ value: "korean", label: "Korean" },
	{ value: "kosovan", label: "Kosovan" },
	{ value: "kurdish", label: "Kurdish" },
	{ value: "kuwaiti", label: "Kuwaiti" },
	{ value: "kyrgyz", label: "Kyrgyz" },
	{ value: "laotian", label: "Laotian" },
	{ value: "latin american", label: "Latin American" },
	{ value: "latvian", label: "Latvian" },
	{ value: "lebanese", label: "Lebanese" },
	{ value: "liberian", label: "Liberian" },
	{ value: "libyan", label: "Libyan" },
	{ value: "liechtensteiner", label: "Liechtensteiner" },
	{ value: "lithuanian", label: "Lithuanian" },
	{ value: "louisiana creole", label: "Louisiana Creole" },
	{ value: "luxembourger", label: "Luxembourger" },
	{ value: "macanese", label: "Macanese" },
	{ value: "macedonian", label: "Macedonian" },
	{ value: "malagasy", label: "Malagasy" },
	{ value: "malawian", label: "Malawian" },
	{ value: "malaysian", label: "Malaysian" },
	{ value: "maldivian", label: "Maldivian" },
	{ value: "malian", label: "Malian" },
	{ value: "maltese", label: "Maltese" },
	{ value: "marshallese", label: "Marshallese" },
	{ value: "martiniquais", label: "Martiniquais" },
	{ value: "mauritanian", label: "Mauritanian" },
	{ value: "mauritian", label: "Mauritian" },
	{ value: "mexican", label: "Mexican" },
	{ value: "micronesian", label: "Micronesian" },
	{ value: "moldovan", label: "Moldovan" },
	{ value: "monacan", label: "Monacan" },
	{ value: "monegasque", label: "Monegasque" },
	{ value: "mongolian", label: "Mongolian" },
	{ value: "montenegrin", label: "Montenegrin" },
	{ value: "montserratian", label: "Montserratian" },
	{ value: "moroccan", label: "Moroccan" },
	{ value: "mosotho", label: "Mosotho" },
	{ value: "motswana", label: "Motswana" },
	{ value: "mozambican", label: "Mozambican" },
	{ value: "namibian", label: "Namibian" },
	{ value: "nauruan", label: "Nauruan" },
	{ value: "nepalese", label: "Nepalese" },
	{ value: "new zealander", label: "New Zealander" },
	{ value: "nicaraguan", label: "Nicaraguan" },
	{ value: "nigerian", label: "Nigerian" },
	{ value: "nigerien", label: "Nigerien" },
	{ value: "niuean", label: "Niuean" },
	{ value: "ni-Vanuatu", label: "Ni-Vanuatu" },
	{ value: "north korean", label: "North Korean" },
	{ value: "northern european", label: "Northern European" },
	{ value: "northern irish", label: "Northern Irish" },
	{ value: "northern mariana islander", label: "Northern Mariana Islander" },
	{ value: "norwegian", label: "Norwegian" },
	{ value: "omani", label: "Omani" },
	{ value: "other", label: "Other" },
	{ value: "pacific islander", label: "Pacific Islander" },
	{ value: "pakistani", label: "Pakistani" },
	{ value: "palauan", label: "Palauan" },
	{ value: "palestinian", label: "Palestinian" },
	{ value: "panamanian", label: "Panamanian" },
	{ value: "papua new guinean", label: "Papua New Guinean" },
	{ value: "paraguayan", label: "Paraguayan" },
	{ value: "pennsylvania dutch", label: "Pennsylvania Dutch" },
	{ value: "peruvian", label: "Peruvian" },
	{ value: "pitcairn islander", label: "Pitcairn Islander" },
	{ value: "polish", label: "Polish" },
	{ value: "portuguese", label: "Portuguese" },
	{ value: "prydeinig", label: "Prydeinig" },
	{ value: "puerto rican", label: "Puerto Rican" },
	{ value: "qatari", label: "Qatari" },
	{ value: "romanian", label: "Romanian" },
	{ value: "russian", label: "Russian" },
	{ value: "rwandan", label: "Rwandan" },
	{ value: "saint lucian", label: "Saint Lucian" },
	{ value: "salvadoran", label: "Salvadoran" },
	{ value: "sammarinese", label: "Sammarinese" },
	{ value: "samoan", label: "Samoan" },
	{ value: "sao tomean", label: "Sao Tomean" },
	{ value: "saudi arabian", label: "Saudi Arabian" },
	{ value: "scandinavian", label: "Scandinavian" },
	{ value: "scotch-irish", label: "Scotch-Irish" },
	{ value: "scottish", label: "Scottish" },
	{ value: "senegalese", label: "Senegalese" },
	{ value: "sephardic jewish", label: "Sephardic Jewish" },
	{ value: "serbian", label: "Serbian" },
	{ value: "seychellois", label: "Seychellois" },
	{ value: "sierra leonean", label: "Sierra Leonean" },
	{ value: "singaporean", label: "Singaporean" },
	{ value: "slovak", label: "Slovak" },
	{ value: "slovene", label: "Slovene" },
	{ value: "solomon islander", label: "Solomon Islander" },
	{ value: "somali", label: "Somali" },
	{ value: "south african", label: "South African" },
	{ value: "south korean", label: "South Korean" },
	{ value: "south sudanese", label: "South Sudanese" },
	{ value: "southeast asian", label: "Southeast Asian" },
	{ value: "spanish", label: "Spanish" },
	{ value: "sri lankan", label: "Sri Lankan" },
	{ value: "st helenian", label: "St Helenian" },
	{ value: "st lucian", label: "St Lucian" },
	{ value: "subsaharan african", label: "Subsaharan African" },
	{ value: "sudanese", label: "Sudanese" },
	{ value: "surinamese", label: "Surinamese" },
	{ value: "swazi", label: "Swazi" },
	{ value: "swedish", label: "Swedish" },
	{ value: "swiss", label: "Swiss" },
	{ value: "syrian", label: "Syrian" },
	{ value: "taiwanese", label: "Taiwanese" },
	{ value: "tajik", label: "Tajik" },
	{ value: "tanzanian", label: "Tanzanian" },
	{ value: "thai", label: "Thai" },
	{ value: "togolese", label: "Togolese" },
	{ value: "tokelauan", label: "Tokelauan" },
	{ value: "tongan", label: "Tongan" },
	{ value: "trinidadian or tobagonian", label: "Trinidadian or Tobagonian" },
	{ value: "tristanian", label: "Tristanian" },
	{ value: "tunisian", label: "Tunisian" },
	{ value: "turkish", label: "Turkish" },
	{ value: "turkmen", label: "Turkmen" },
	{ value: "turks and caicos islander", label: "Turks and Caicos Islander" },
	{ value: "tuvaluan", label: "Tuvaluan" },
	{ value: "ugandan", label: "Ugandan" },
	{ value: "ukrainian", label: "Ukrainian" },
	{ value: "uruguayan", label: "Uruguayan" },
	{ value: "uzbek", label: "Uzbek" },
	{ value: "venezuelan", label: "Venezuelan" },
	{ value: "vietnamese", label: "Vietnamese" },
	{ value: "vincentian", label: "Vincentian" },
	{ value: "wallisian", label: "Wallisian" },
	{ value: "welsh", label: "Welsh" },
	{ value: "west indian", label: "West Indian" },
	{ value: "western european", label: "Western European" },
	{ value: "yemeni", label: "Yemeni" },
	{ value: "yugoslavian", label: "Yugoslavian" },
	{ value: "zambian", label: "Zambian" },
	{ value: "zimbabwean", label: "Zimbabwean"}
]

const app_constants = {
  login: {
    max_login_attempts: 3
  },
  patientData: {
    genders: {
      male:    {value: 'M', label: 'Male'},
      female:  {value: 'F', label: 'Female'},
      unknown: {value: 'U', label: 'Unknown'}
    },
    genderIdentity: {
      male: {value: 'male', label: 'Male'},
      female: {value: 'female', label: 'Female'},
      nonBinary: {value: 'non-binary', label: 'Non-Binary'}
    },
    pronouns: {
      h: {value: 'he/him', label: 'He/Him'},
      s: {value: 'she/her', label: 'She/Her'},
      t: {value: 'they/them', label: 'They/Them'},
      o: {value: 'other', label: 'Other'}
    },
    riskFactorKeys: {
      general_risk_factors: 'generalRiskFactors',
      procedures_risk_factors: 'proceduresRiskFactors',
      reproductive_risk_factors: 'reproductiveRiskFactors',
      surgeries_risk_factors: 'surgeriesRiskFactors',
      alcohol_risk_factors: 'alcoholRiskFactors',
      oc_risk_factors: 'OCRiskFactors'
    }
  },
  riskModels: {
    boadicea: 'BOADICEA',
    claus: 'CLAUS',
    gail: 'GAIL',
    tyrer_cuzick: 'TYRER-CUZICK',
    premm: 'PREMM',
    brcapro: 'BRCAPRO',
    mmrpro: 'MMRpro',
    pancpro: 'PANCpro',
    melapro: 'MELApro'
  },
  clientTypes: {
	white_label_vpc: {value: 'white_label_vpc', label: 'White Label - VPC'},
	white_label_multi_tenant: {value: 'white_label_multi_tenant', label: 'White Label - Multi-tenant'},
	standard: {value: 'standard', label: 'Standard'},
  },
  superOrg:    {value: 'super_org', label: 'Super Org'},
  subOrg:      {value: 'sub_org', label: 'Sub Org'},
  standardOrg: {value: 'standard_org', label: 'Standard'},
  famhisOrg: {value: 'super_admin', label: 'FamHis'},
  trialOrg: {value: 'trial_org', label: 'Trial'},
  freeOrg: {value: 'free', label: 'Free'},
  studentOrg: {value: 'student', label: 'Student'},

  ashkanaziAndRace : {value: 'show_ashkanazi_and_race', label : 'Show both Ashkenazi Jewish and Race/Ethnicity questions'},
  ashkanaziOnly : {value: 'show_ashkanazi', label : 'Show Ashkanazi Jewish quesions only'},
  hideAshkanaziAndRace : {value: 'hide_ashkanazi_and_race', label : 'Hide both Ashkanazi Jewish and Race/Ethnicity questions'},
  defaultPartnerViewOptions: {
	show_all_partners: {value: 'show_all_partners', label: 'Show all partners' },
	show_affected_partners: {value: 'show_affected_partners', label: 'Show affected partners' },
	hide_partners: {value: 'hide_partners', label: 'Hide partners' }
  }
}

const post_menopausal_options = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'starting_to_go_through_menopause', label: 'Starting to go through menopause' }
]

const taken_hrt_options = [
  { value: 'no', label: 'No' },
  { value: 'yes_5_or_more_years_ago', label: 'Yes - 5 or more years ago' },
  { value: 'yes_less_than_5_years_ago', label: 'Yes - Less than 5 years ago' },
  { value: 'yes_current_user', label: 'Yes - Current user' }
]

const hrt_types_options = [
  { value: 'estrogen', label: 'Estrogen' },
  { value: 'combined', label: 'Combined' },
  { value: 'other', label: 'Other' },
  { value: 'unknown', label: 'Unknown' }
]

const mastectomy_type_options = [
  { value: 'one_breast', label: 'One breast' },
  { value: 'both_breasts', label: 'Both breasts' }
]

const oophorectomy_type_options = [
  { value: 'one_ovary', label: 'One ovary' },
  { value: 'both_ovaries', label: 'Both ovaries' }
]

const breast_density_method_options = [
  { value: 'bi_rads', label: 'BI-RADS' },
  { value: 'volpara', label: 'Volpara' },
  { value: 'vas_other_continuous_measure', label: 'VAS/Other continuous measure' }
]

const birads_density_options = [
  { value: 'unknown', label: 'Unknown' },
  { value: 'A_almost_entirely_fatty', label: 'A: Almost entirely fatty' },
  { value: 'B_scattered_fibroglandular_density', label: 'B: Scattered fibroglandular density' },
  { value: 'C_heterogeneously_dense', label: 'C: Heterogeneously dense' },
  { value: 'D_entirely_dense', label: 'D: Entirely dense' }
]

const drinks_per_day_options = [
  { value: '0_drinks', label: '0' },
  { value: 'less_than_1_drink', label: 'Less than 1' },
  { value: '1_drink', label: '1' },
  { value: '2_drinks', label: '2' },
  { value: '3_drinks', label: '3' },
  { value: '4_drinks', label: '4' },
  { value: '5_or_more_drinks', label: '5 or more' }
]

const oral_contraceptive_years_options = [
  { value: 'ocp_less_than_year', label: 'Less than 1 year' },
  { value: 'ocp_1_to_4_years', label: '1-4 years' },
  { value: 'ocp_5_to_9_years', label: '5-9 years' },
  { value: 'ocp_10_to_14_years', label: '10-14 years' },
  { value: 'ocp_15_or_more_years', label: '15 or more years' }
]

const clinician_notification_types = [
  'patient_updated_family_member_data',
  'patient_updated_their_data',
  'patient_added_family_member',
  'patient_deleted_family_member',
  'patient_added_family_parents',
  'proband_reconnected'
]

export default {
  marriage_status_options,
  pregnancy_options,
  pregnancy_options_2,
  twins_set_options,
  twins_type_options,
  adopted_status_options,
  half_siblings_shared_parent_options,
  half_siblings_other_parent_options,
  ethnicity_options,
  ancestry,
  app_constants,
  post_menopausal_options,
  taken_hrt_options,
  hrt_types_options,
  mastectomy_type_options,
  oophorectomy_type_options,
  breast_density_method_options,
  birads_density_options,
  drinks_per_day_options,
  oral_contraceptive_years_options,
  clinician_notification_types
}
